<template>
  <v-form ref="loginForm" @submit.prevent="login">
    <v-text-field
      solo
      v-model="user.email"
      :rules="[rules.required, rules.email]"
      :label="$t('common.email')"
      prepend-inner-icon="mdi-account"
    ></v-text-field>
    <v-text-field
      solo
      v-model="user.password"
      :rules="[rules.required]"
      type="password"
      :label="$t('common.password')"
      prepend-inner-icon="mdi-lock"
    ></v-text-field>
    <v-card-actions class="pa-0">
      <v-btn dark block color="primary" type="submit" :loading="loading">
        {{ $t('auth.login') }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import validators from '../../common/validators';
import { LOGIN } from '../../store/auth/types';

export default {
  name: 'login-form',
  data() {
    return {
      loading: false,
      user: {
        email: '',
        password: ''
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        email: validators.isEmail(this.$i18n.t('common.invalidEmail'))
      }
    };
  },
  methods: {
    async login() {
      if (!this.$refs.loginForm.validate()) {
        return;
      }

      this.loading = true;

      try {
        const result = await this.$store.dispatch(LOGIN, this.user);
        this.$i18n.locale = result.user.lang;

        this.$router.push({ path: '/dashboard' });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
