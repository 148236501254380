<template>
  <v-container class="pa-6">
    <v-row justify="space-around">
      <v-col lg="7" md="6" cols="12">
        <v-responsive class="hidden-xs-only hidden-sm-only">
          <v-img
            max-height="500"
            contain
            class="border-radius-10"
            :src="images.heroes"
          ></v-img>
        </v-responsive>
      </v-col>
      <v-col lg="5" md="6" cols="12">
        <login-panel></login-panel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginPanel from '../components/auth/LoginPanel';
import { ACTIVATE_ACCOUNT } from '../store/auth/types';

export default {
  name: 'login',
  components: {
    LoginPanel
  },
  created() {
    const { activationToken } = this.$route.query;

    if (activationToken) {
      this.activateAccount(activationToken);
    }
  },
  data() {
    return {
      images: {
        heroes: require('../assets/images/gamifinnator-gamifinna.png')
      }
    };
  },
  methods: {
    async activateAccount(activationToken) {
      await this.$store.dispatch(ACTIVATE_ACCOUNT, { activationToken });

      this.$notify({
        type: 'success',
        text: this.$i18n.t('auth.accountSuccessfullyActivated')
      });
    }
  }
};
</script>
