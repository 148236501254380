<template>
  <v-card class="login-form">
    <v-container class="pa-6">
      <h4 class="text-h5 text-center mb-6 font-weight-light text-capitalize">
        {{ $t('auth.login') }}
      </h4>
      <social-auth></social-auth>
      <h4 class="text-h6 text-center my-3 font-weight-light text-uppercase">
        {{ $t('common.or') }}
      </h4>
      <v-btn
        block
        outlined
        color="app-blue"
        dark
        class="mb-4"
        @click="toggle()"
      >
        {{ $t('auth.gamLogin') }}
        <v-icon right dark v-if="!gamLogin">mdi-chevron-down</v-icon>
        <v-icon right dark v-else>mdi-chevron-up</v-icon>
      </v-btn>
      <div v-if="gamLogin">
        <login-form v-if="gamLogin"></login-form>
        <div class="text-center mt-2">
          <router-link
            class="app-blue--text text-center"
            :to="'password-recovery'"
          >
            {{ $t('auth.forgotPassword') }}
          </router-link>
        </div>
      </div>
      <div class="text-center mt-3">
        <span>{{ $t('auth.dontHaveAnAccount') }}</span>
        <router-link to="/sign-up" color="app-blue">
          {{ $t('auth.signUpForFree') }}
        </router-link>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import SocialAuth from './SocialAuth';
import LoginForm from './LoginForm';

export default {
  name: 'login-panel',
  components: {
    SocialAuth,
    LoginForm
  },
  data() {
    return {
      gamLogin: false
    };
  },
  methods: {
    toggle() {
      this.gamLogin = !this.gamLogin;
    }
  }
};
</script>
